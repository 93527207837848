import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useInvoicesList() {
    // Use toast
    const toast = useToast()

    const refInvoiceListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        // { key: 'index', label: '#' },
        { key: 'index', label: '#' },
        { key: 'actions' , label : 'อัพเดท' },
        { key: 'cancel' , label : 'ยกเลิก' },
        {
            key: "code",
            label: "รหัสการชำระเงิน",
        },
        {
            key: "cust_code",
            label: "รหัสลูกค้า",
        },
        {
            key: "slip",
            label: "สลิป",
         },
        {
            key: "shop_name",
            label: "ร้านค้า",

        },
        {
            key: "amount",
            label: "ยอดเงิน",
        },
      
        {
            key: "invoice",
            label: "ใบแจ้งหนี้",
        },

        // {
        //     key: "invoice_id",
        //     label: "invoice_id",
        //     // sortable: true

        // },
        // {
        //     key: "order_row",
        //     label: "ออเดอร์",

        // },
        {
            key: "pay_date",
            label: "วันที่ชำระเงิน",
        },
       
        {
            key: "status_payment",
            label: "สถานะการชำระเงิน",
        },
        // { key: 'id', label: '#', sortable: true },
        // { key: 'invoiceStatus', sortable: true },
        // { key: 'actions' },
        // { key: 'client', sortable: true },
        // { key: 'total', sortable: true, formatter: val => `$${val}` },
        // { key: 'issuedDate', sortable: true },
        // { key: 'Endate', sortable: true },
        // { key: 'Endate1', sortable: true },
        // { key: 'Endate2', sortable: true },
        // { key: 'balance', sortable: true },


   

    ]
    const tableColumnsrows = [
        {
            key: "shop_code",
            label: "รหัสรายการ",
        },
        {
            key: "transfer_zone",
            label: "สาย",


        },
        {
            key: "order_date",
            label: "วันส่งสินค้า",

        },
        {
            key: "code",
            label: "รหัส",
        },
        {
            key: "shop_name",
            label: "ร้านค้า",

        },
        {
            key: "size_small",
            label: "สินค้า(เล็ก)",
        },
        {
            key: "size_middle",
            label: "สินค้า(กลาง)",
        },
        {
            key: "size_large",
            label: "สินค้า(ใหญ่)",
        },
        {
            key: "remark",
            label: "อื่นๆ",
        },
        {
            key: "total",
            label: "รวม",
            // sortable: true

        },
        // {
        //     key: "code",
        //     label: "NO.INVOICE",

        // },
        {
            key: "status_order",
            label: "สถานะ",
        },
        // { key: 'id', label: '#', sortable: true },
        // { key: 'invoiceStatus', sortable: true },
        // { key: 'actions' },
        // { key: 'client', sortable: true },
        // { key: 'total', sortable: true, formatter: val => `$${val}` },
        // { key: 'issuedDate', sortable: true },
        // { key: 'Endate', sortable: true },
        // { key: 'Endate1', sortable: true },
        // { key: 'Endate2', sortable: true },
        // { key: 'balance', sortable: true },

    ]


    // var date = new Date();
    // date  = date.substring(0, 10)
    // const date = new Date();
    var date = new Date();

var result = "" + date.getFullYear() +'-'+ ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1) +'-'+ (date.getDate() > 9 ? '' : '0') + date.getDate();
    
// const [month, day, year] = [
//         date.getMonth(),
//         date.getDate(),
//         date.getFullYear(),
//     ];


    // console.log(month, day, year)


    // date = [{day: 'numeric'}, {month: 'short'}, {year: 'numeric'}];
    // let s = join(new Date, a, '-');
    // console.log(s);


    const perPage = ref(1)
    const totalInvoices = ref(0)
    const excel= ref([])

    const max_large = ref(0)
    const max_middle = ref(0)
    const max_small = ref(0)

    const totalInvoices1 = ref([])
    const totalInvoices2 = ref([])
    const params = ref('')

   
   
    const perPageOptions = [5, 25, 50, 100]
    const searchQuery = ref('')
    const searchStartDate =  ref(result)
    // const searchStartDate = ref(year+'-'+month+"-"+day)

    const start = Date.now();

    const searchEndDate= ref('')
    const exportD = ref('')

    const currentPage = ref(30)
    const searchEndDate1 = ref(1)

    const round = ref('')
    const license = ref('')
    const searchStatus = ref('')
    const searchRout = ref('')

    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref(null)

    const dataMeta = computed(() => {
        const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
        return {
            from: currentPage.value * (searchEndDate1.value - 1) + (localItemsCount ? 1 : 0),
            to: currentPage.value * (searchEndDate1.value - 1) + localItemsCount,
            of: totalInvoices.value,
        }
    })

    const refetchData = () => {
        refInvoiceListTable.value.refresh()
    }

    watch([currentPage,license,round, perPage, searchQuery, statusFilter, searchStartDate, searchEndDate, searchEndDate1,searchStatus, searchRout], () => {
    //    console.log("1")
        refetchData()
    })

    const fetchInvoices = (ctx, callback) => {



        // console.log("year" , year)
        // console.log("month" , month)
        // console.log("day" , day)


        // if(month < 10){
        //     console.log("1")
        //     month = `0`+ month 
        // }

        // status
        // console.log("month1" , month +`0`)

        //     let payload  = {
        //     "dateFrom": searchStartDate.value ,
        //     "status" : "",
        //     "license" : "",
        //     "shopName" : "",
        //     "round" : ""
        // }
        let payload = { "dateFrom": searchStartDate.value ? searchStartDate.value : searchStartDate.value, 
        "dateTo": searchEndDate.value ? searchEndDate.value : searchStartDate.value,
         "status": searchStatus.value,
        //   "round": round.value,
        //  "license" : license.value,
           "shopName": statusFilter.value ?    statusFilter.value  : ""
        }
        // let payload  = { }

        store.dispatch('app-invoice/listpaymentexport', payload 
        )
        .then(response => {
var value1 = response.data

// for (let i = 0; i < response.data.length; i++) {
    // console.log("test", response.data)
    // console.log("searchEndDate1", searchEndDate1.value)
    // console.log("perpage", currentPage.value)              
        // if( value[i].order_row == 1){
            // count = count+1
            // console.log("1=",count)
            // value1[i].index = count
            // console.log(  "1" ,value[i].slip)
            // if(value[i].slip  == null  || value[i].slip  == ``){
            //     value[i].slip = ""
            // }else{
            //     img = value[i].slip.data.map(b => String.fromCharCode(b)).join('')
            //      value[i].slip  = img
            // }
        // }
// }

        exportD.value = value1

        // console.log("exportD", exportD)
        
            // console.log("1")
            // console.log("test",response.data)
           
            // var value2 = response.data

            // console.log("test01")

            // totalInvoices2.value = value2
            // console.log("test02")
      
        })
        .catch(() => {
        })

        store.dispatch('app-invoice/listpayment', payload 
            )
            .then(response => {
                // const { invoices, total } = response
                var value = response.data
                var count = 0
                var img = ``
                for (let i = 0; i < response.data.length; i++) {
                    console.log("test", response.data)
                    // console.log("searchEndDate1", searchEndDate1.value)
                    // console.log("perpage", currentPage.value)              
                        if( value[i].order_row == 1){
                            count = count+1
                            // console.log("1=",count)
                            value[i].index = count
                            // console.log(  "1" ,value[i].slip)
                            if(value[i].slip  == null  || value[i].slip  == ``){
                                value[i].slip = ""
                            }else{
                                img = value[i].slip.data.map(b => String.fromCharCode(b)).join('')
                                 value[i].slip  = img
                            }
                        }else{
                            value[i].checkbox = false
                            value[i].slip  =  ``
                        }
                }


                
                params.value = value    
                console.log(params.value.length)
                let perPage  = currentPage.value 
                let page = searchEndDate1.value

                let records = [];
                const startIndex =  perPage * (page - 1) + 1;
                const endIndex = startIndex + perPage - 1;

                for (let i = startIndex; i <= endIndex; i++) {
                    if(params.value[i-1] == undefined){
                    }else{
                        records.push(params.value[i-1]); 
                    }
                   
                }

                callback(records)
                totalInvoices.value = value.length
                totalInvoices1.value = records
                // totalInvoices2.value = "1"
                // excel.value =  params.value
            })
            .catch(() => {
            })



          
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveInvoiceStatusVariantAndIcon = status => {
        if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' }
        if (status === 'Paid') return { variant: 'success', icon: 'CheckCircleIcon' }
        if (status === 'Downloaded') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
        if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
        if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
        if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
        return { variant: 'secondary', icon: 'XIcon' }
    }

    const resolveClientAvatarVariant = status => {
        if (status === 'Partial Payment') return 'primary'
        if (status === 'Paid') return 'danger'
        if (status === 'Downloaded') return 'secondary'
        if (status === 'Draft') return 'warning'
        if (status === 'Sent') return 'info'
        if (status === 'Past Due') return 'success'
        return 'primary'
    }

    return {
        fetchInvoices,
        tableColumns,
        tableColumnsrows,
        perPage,
        excel,
        currentPage,
        totalInvoices,
        totalInvoices1,
        totalInvoices2,
        params,
        dataMeta,
        exportD,
        perPageOptions,
        searchQuery,
        searchStartDate,
        searchEndDate,
        searchEndDate1,
        license,round,
        searchStatus,
        searchRout,
        sortBy,
        isSortDirDesc,
        refInvoiceListTable,

        statusFilter,

        resolveInvoiceStatusVariantAndIcon,
        resolveClientAvatarVariant,

        refetchData,
    }
}
