<template>
  <!-- Table Container Card -->
  <!-- <b-overlay
    v-if="!cardClosed"
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  > -->

  <b-overlay v-if="show == false" :show="show" rounded="sm" no-fade>
    <div :aria-hidden="show ? 'true' : null">
      <b-card no-body>
        <div class="m-2">
          <div class="container mt-3">
            <div class="row">
              <div class="col-sm">
                <b-card
                  bg-variant="primary"
                  text-variant="white"
                  header="บิลทั้งหมด"
                  class="text-center"
                >
                  <b-card-text style="font-size: 34px">
                    {{
                      show_data0_total +
                      show_data1_bill_success +
                      show_data2_bill_can
                    }}
                  </b-card-text>
                </b-card>
              </div>
              <div class="col-sm">
                <b-card
                  bg-variant="secondary"
                  text-variant="white"
                  header="บิลชำระเงิน"
                  class="text-center"
                >
                  <b-card-text style="font-size: 34px">
                    {{ show_data1_bill_success }}</b-card-text
                  >
                </b-card>
              </div>
              <div class="col-sm">
                <b-card
                  bg-variant="success"
                  text-variant="white"
                  header="บิลค้าง"
                  class="text-center"
                >
                  <b-card-text style="font-size: 34px">
                    {{
                      show_data0_total - show_data2_bill_can > 0
                        ? show_data0_total - show_data2_bill_can
                        : 0
                    }}
                  </b-card-text>
                </b-card>
              </div>
              <!-- <div class="col-sm">

              <b-card
                style="background-color: orange"
                text-variant="white"
                header="จำนวนสินค้า(รวม)"
                class="text-center"
              >
                <b-card-text style="font-size: 34px">{{
                  totalInvoices5
                }}</b-card-text>
              </b-card>


            </div> -->
            </div>
          </div>
          <!-- tab 1 -->
          <b-row style="margin-top: 10px; margin-left: 10px">
            <b-col cols="12" md="10">
              <div
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <b-form-datepicker
                  id="datepicker-dateformat1"
                  class="col-3 mr-2"
                  v-model="searchStartDate"
                  type="text"
                  placeholder="วันที่เริ่มต้น"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    weekday: 'short',
                  }"
                  locale="en"
                ></b-form-datepicker>

                <!-- {{ searchStartDate }} -->

                <b-form-datepicker
                  id="datepicker-dateformat2"
                  class="col-3 mr-2"
                  v-model="searchEndDate"
                  type="text"
                  placeholder="วันที่สิ้นสุด"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    weekday: 'short',
                  }"
                  locale="en"
                ></b-form-datepicker>

                <v-select
                  v-model="searchStatus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  class="col-3 mr-2"
                  placeholder="สถานะ"
                  style="padding-right: 0rem; padding-left: 0rem"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>
              </div>
            </b-col>
          </b-row>

          <b-row style="margin-top: 10px; margin-left: 10px">
            <b-col cols="12" md="10" class="mt-2">
              <div
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <v-select
                  v-model="statusFilter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="options"
                  class="col-7 mr-2"
                  placeholder="ชื่อร้านค้า"
                  style="padding-right: 0rem; padding-left: 0rem"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>

                <!-- test01 :  {{ totalInvoices2 }} -->
                <!-- test02 :  {{ exportD }}
                test03 :  {{ params }} -->

                <!-- {{ options }} -->

                <!-- <b-form-input
                            v-model="searchRout"
                            class="d-inline-block mr-2 col-3"
                            placeholder="สาย..."
                        /> -->

                <!-- <v-select
                            v-model="round"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="Optionsrob"
                            class="col-3 mr-2"
                            placeholder="รอบ"
                            style="padding-right: 0rem; padding-left: 0rem"
                        >
                            <template #selected-option="{ label }">
                                <span class="text-truncate overflow-hidden">
                                    {{ label }}
                                </span>
                            </template>
                        </v-select> -->

                <b-button
                  class="ml-2 col-2"
                  variant="primary"
                  @click="createNew()"
                >
                  สร้างรายการ
                </b-button>

                <b-button
                  class="ml-2 col-2"
                  variant="secondary"
                  @click="ClearData"
                >
                  เคลียร์ข้อมูล
                </b-button>
              
                <!-- {{ update_data }} -->
                <b-button class="ml-2" variant="success">
                 
               
                  <download-excel
                    :data="exportD"
                    :fields="fields"
                    name="การเงิน"
                  >
                    ดาวน์โหลด
                  </download-excel></b-button
                >
                <!-- <v-select
                            v-model="searchStatus"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="statusOptions"
                            class="col-3 mr-2"
                            placeholder="สถานะ"
                            style="padding-right: 0rem; padding-left: 0rem"
                        >
                            <template #selected-option="{ label }">
                                <span class="text-truncate overflow-hidden">
                                    {{ label }}
                                </span>
                            </template>
                        </v-select> -->

                <!-- <v-select
                            v-model="statusFilter"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="options"
                            class="col-3 mr-2"
                            placeholder="ชื่อร้านค้า"
                            style="padding-right: 0rem; padding-left: 0rem"
                        >
                            <template #selected-option="{ label }">
                                <span class="text-truncate overflow-hidden">
                                    {{ label }}
                                </span>
                            </template>
                        </v-select> -->
                <!-- <v-select
                            v-model="license"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="options_carLicense"
                            class="col-3 mr-2"
                            placeholder="ทะเบียนรถ"
                            style="padding-right: 0rem; padding-left: 0rem"
                        >
                            <template #selected-option="{ label }">
                                <span class="text-truncate overflow-hidden">
                                    {{ label }}
                                </span>
                            </template>
                        </v-select> -->
              </div>
            </b-col>
          </b-row>
        </div>

        <b-modal
          ref="cancel"
          hide-footer
          title="ยกเลิกรายการ"
          no-close-on-backdrop
        >
          <!-- <div class="d-block text-center"> -->
          <!-- <h3>รายละเอียด</h3> -->
          <!-- <img :src="chang_img"   width="60%"> -->
          <!-- </div> -->

          <b-card-text>
            <p>คุณต้องการยกเลิกรายการหรือไม่ ?</p>
            <!-- <b-form-input
              v-model="formData_cancle.cancel_remark"
              placeholder="สาเหตุที่ยกเลิก"
              type="text"
            ></b-form-input> -->
          </b-card-text>
          <!-- <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Close Me</b-button> -->
          <b-button
            class="mt-2"
            variant="success"
            block
            @click="submit_cancel_invoice()"
            >ตกลง</b-button
          >
        </b-modal>

        <b-modal
          ref="submit-new"
          hide-footer
          title="สร้างรายการ"
          no-close-on-backdrop
        >
          <div class="d-block">
            <v-select
              placeholder="ชื่อร้านค้า"
              v-model="name_create"
              :options="options_code"
              :reduce="(country) => country.code"
              label="country"
            />

            <div
              class="slide mt-5"
              style="margin-left: 60px"
              v-for="(checkbox, index) in options_checkbox"
              :key="index"
            >
              <p style="text-align: start; font-size: 16px">
                วันที่ส่ง :
                <span style="color: green"> {{ checkbox.transferDate }} </span>
              </p>
              <p style="text-align: start; font-size: 16px">
                ยอดเงิน :
                <span style="color: green"> {{ checkbox.amount }}</span>
              </p>
              <p style="text-align: start; font-size: 16px">
                remark :
                <span style="color: gray">{{ checkbox.remark }} </span>
              </p>
              <p style="text-align: start; font-size: 16px">
                สถานะ :
                <span style="color: gray">{{ checkbox.statusPayment }} </span>
              </p>

              <b-form-checkbox
                name="check-button"
                @input="
                  (value) =>
                    submitCheck(
                      checkbox.value,
                      checkbox.amount,
                      checkbox.check_checkbox,
                      checkbox.text,
                      checkbox.remark
                    )
                "
              >
                {{ checkbox.text }}
              </b-form-checkbox>
            </div>

            <div
              style="text-align: start; font-size: 19px; margin-left: 60px"
              v-if="total_amount"
            >
              <b>
                สรุปยอดเงิน =
                <span style="color: green"> {{ total_amount }} </span>
              </b>
            </div>
            <!-- {{  formData_create.slip }} -->
            <div class="c33 text-center mt-5" v-if="total_amount">
              <UploadImagePreview
                :previewHeight="`200px`"
                :previewWidth="`200px`"
                v-model="formData_create.slip"
                @remove="formData_create.slip = ''"
              />

              <div class="text-botton" style="color: red">
                *** แนบหลักฐานการชำระเงิน
              </div>
            </div>

            <div class="form-input pb-2 row mt-35"></div>

            <div></div>
            <div class="d-flex">
              <div style="width: 20%"></div>
              <div style="width: 40%"></div>
            </div>
          </div>
          <!-- <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Close Me</b-button> -->
          <div class="modal-footer">
            <!-- <button
                  type="button"
                  class="btn btn-primary"
                  variant="success"
                  @click="submitCreateNew"
                  v-if="formData_modal.custCode != ``"
                >
                  บันทึก
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  variant="success"
                  v-else
                  disabled
                >
                  บันทึก
                </button> -->

            <button
              type="button"
              v-if="formData_create.slip"
              @click="Saveinvoice"
              class="btn btn-primary"
              variant="success"
            >
              บันทึก
            </button>
            <button
              type="button"
              v-else
              disabled
              class="btn btn-primary"
              variant="success"
            >
              บันทึก
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              variant="danger"
              @click="hideModal_submit"
            >
              ปิด
            </button>
          </div>
        </b-modal>
        <b-modal
          ref="submit-new-update"
          hide-footer
          title="สร้างรายการ"
          no-close-on-backdrop
        >
          <div class="d-block">
            <v-select
              placeholder="ชื่อร้านค้า"
              v-model="name_create"
              :options="options_code"
              :reduce="(country) => country.code"
              label="country"
            />
            <!-- <v-select
              placeholder="ชื่อร้านค้า"
              v-model="name_create"
              :options="options_code"
              :reduce="(country) => country.code"
              label="country"
            /> -->

            <div
              class="slide mt-5"
              style="margin-left: 60px"
              v-for="(checkbox, index) in options_checkbox"
              :key="index"
            >
              <p style="text-align: start; font-size: 16px">
                วันที่ส่ง :
                <span style="color: green"> {{ checkbox.transferDate }} </span>
              </p>
              <p style="text-align: start; font-size: 16px">
                ยอดเงิน :
                <span style="color: green"> {{ checkbox.amount }}</span>
              </p>
              <p style="text-align: start; font-size: 16px">
                remark :
                <span style="color: gray">{{ checkbox.remark }} </span>
              </p>
              <p style="text-align: start; font-size: 16px">
                สถานะ :
                <span style="color: gray">{{ checkbox.statusPayment }} </span>
              </p>

              <b-form-checkbox
                name="check-button"
                @input="
                  (value) =>
                    submitCheck(
                      checkbox.value,
                      checkbox.amount,
                      checkbox.check_checkbox,
                      checkbox.text,
                      checkbox.remark
                    )
                "
              >
                {{ checkbox.text }}
              </b-form-checkbox>
            </div>

            <div
              style="text-align: start; font-size: 19px; margin-left: 60px"
              v-if="total_amount"
            >
              <b>
                สรุปยอดเงิน =
                <span style="color: green"> {{ total_amount }} </span>
              </b>
            </div>
            <!-- {{  formData_create.slip }} -->

            <!-- <div class="c33 text-center mt-5" v-if="total_amount">
              <UploadImagePreview
                :previewHeight="`200px`"
                :previewWidth="`200px`"
                v-model="formData_create.slip"
                @remove="formData_create.slip = ''"
              />

              <div class="text-botton" style="color: red">
                *** แนบหลักฐานการชำระเงิน
              </div>
            </div> -->

            <div class="form-input pb-2 row mt-35"></div>

            <div></div>
            <div class="d-flex">
              <div style="width: 20%"></div>
              <div style="width: 40%"></div>
            </div>
          </div>
          <!-- <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Close Me</b-button> -->
          <div class="modal-footer">
            <!-- <button
                  type="button"
                  class="btn btn-primary"
                  variant="success"
                  @click="submitCreateNew"
                  v-if="formData_modal.custCode != ``"
                >
                  บันทึก
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  variant="success"
                  v-else
                  disabled
                >
                  บันทึก
                </button> -->

            <button
              type="button"
              @click="Updateinvoice"
              class="btn btn-primary"
              variant="success"
            >
              บันทึก
            </button>

            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              variant="danger"
              @click="payment_status_hide"
            >
              ปิด
            </button>
          </div>
        </b-modal>

        <template>
          <div>
            <b-modal ref="my-modal" hide-footer title="หลักฐานการชำระเงิน">
              <div class="d-block text-center">
                <!-- <h3>หลักฐานการชำระเงิน</h3> -->
                <div v-if="showb == false">
                  <b-overlay
                    show
                    spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                    rounded="sm"
                    style="
                      top: 74%;
                      left: 50%;
                      transform: translateX(-50%) translateY(-50%);
                    "
                  />
                </div>
                <img v-else :src="chang_img" width="60%" />
              </div>

              <div v-if="items_img.status_payment != `ชำระเงินแล้ว`">
                <b-form-checkbox
                  v-model="options_check"
                  name="เลือกทั้งหมด"
                  @input="(value) => check()"
                >
                  เลือกทั้งหมด
                </b-form-checkbox>
              </div>

              <!-- <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Close Me</b-button> -->
              <!-- v-if="row.item.status_payment == `ชำระเงินแล้ว`" -->
              <b-button
                class="mt-2"
                v-if="options_check == true"
                variant="success"
                block
                @click="CheckAll()"
                >ตกลง</b-button
              >
              <b-button class="mt-2" v-else variant="success" block disabled
                >ตกลง</b-button
              >
            </b-modal>
          </div>
        </template>

        <template>
          <div>
            <b-modal
              ref="my-modal1"
              hide-footer
              title="อัพเดท"
              no-close-on-backdrop
            >
              <div class="d-block">
                <h6>คุณต้องการอัพเดทข้อมูลหรือไม่</h6>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  variant="success"
                  @click="UpdateQ(updated.checkbox, updated)"
                >
                  บันทึก
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  variant="danger"
                  @click="hide"
                >
                  ปิด
                </button>
              </div>

              <!-- <b-button
                class="mt-2"
                v-if="options_check == true"
                variant="success"
                block
                @click="CheckAll()"
                >ตกลง</b-button
              > -->

              <!-- <b-button class="mt-2" v-else variant="success" block disabled
                >ตกลง</b-button
              > -->
            </b-modal>
          </div>
        </template>

        <!-- tab 2 -->
        <!-- {{ update_data }} -->
        <!-- {{ totalInvoices1 }} -->
        <!-- {{ fetchInvoices }}  -->
        <!-- {{ fetchInvoices() }} -->
        <!-- <h1>Skor :  {{fetchInvoices[0].item}}</h1> -->
        <!-- {{ fetchInvoices }} -->

        <b-table
          ref="refInvoiceListTable"
          :items="fetchInvoices"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
        >
          <template #head(code)="data">
            <span style="text-align: center">{{ data.label }}</span>
            <!-- <feather-icon icon="TrendingUpIcon" class="mx-auto" /> -->
          </template>
          <template #head(cust_code)="data">
            <span style="text-align: center">{{ data.label }}</span>
            <!-- <feather-icon icon="TrendingUpIcon" class="mx-auto" /> -->
          </template>

          <template #head(slip)="data">
            <span style="text-align: center">{{ data.label }}</span>
            <!-- <feather-icon icon="TrendingUpIcon" class="mx-auto" /> -->
          </template>

          <template #head(shop_name)>
            <div style="text-align: left">ร้านค้า</div>
          </template>

          <template #head(amount)>
            <div style="text-align: right">ร้านค้า</div>
          </template>

          <template #cell(index)="data">
            <div
              v-if="data.value !== ``"
              style="text-align: center"
              :style="{
                width: data.value.key === 'order_date' ? '100%' : '100%',
              }"
            >
              <span>
                <b> {{ data.value }} </b></span
              >
            </div>
            <div
              v-else
              style="text-align: center"
              :style="{
                width: data.value.key === 'order_date' ? '100%' : '100%',
              }"
            ></div>
          </template>

          <template #cell(amount)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'amount' ? '100%' : '100%',
              }"
              style="text-align: end"
            >
              <span style="color: green">
                <b> {{ data.value }} </b></span
              >
            </div>
            <div
              v-else
              style="text-align: center"
              :style="{
                width: data.value.key === 'amount' ? '100%' : '100%',
              }"
            >
              <span v-if="data.item.order_row == 1"> </span>
              <span v-if="data.item.order_row == 2"> - </span>
            </div>
          </template>

          <template #cell(size_small)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'size_small' ? '100%' : '100%',
              }"
            >
              <span style="color: green">
                <b> {{ data.value }} </b></span
              >
            </div>
            <div
              v-else
              :style="{
                width: data.value.key === 'size_small' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>

          <template #cell(invoice)="data">
            <div
              style="text-align: center"
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'invoice' ? '100%' : '100%',
              }"
            >
              <span style="color: green">
                {{ data.value }}
              </span>
            </div>
            <div
              v-else
              style="text-align: center"
              :style="{
                width: data.value.key === 'invoice' ? '100%' : '100%',
              }"
            >
              <span v-if="data.item.order_row == 1"> </span>
              <span v-if="data.item.order_row == 2"> - </span>
            </div>
          </template>

          <template #cell(invoice_id)="data">
            <div
              v-if="data.value !== ``"
              style="text-align: center"
              :style="{
                width: data.value.key === 'invoice_id' ? '100%' : '100%',
              }"
            >
              <span style="color: green"> {{ data.value }} </span>
            </div>
            <div
              v-else
              style="text-align: center"
              :style="{
                width: data.value.key === 'invoice_id' ? '80px' : '80px',
              }"
            >
              -
            </div>
          </template>

          <template #cell(order_row)="data">
            <div
              v-if="data.value !== ``"
              style="text-align: center"
              :style="{
                width: data.value.key === 'order_row' ? '100%' : '100%',
              }"
            >
              <span style="color: blue">
                <b> {{ data.value }} </b></span
              >
            </div>
            <div
              v-else
              style="text-align: center"
              :style="{
                width: data.value.key === 'order_row' ? '100%' : '100%',
              }"
            ></div>
          </template>

          <template #cell(pay_date)="data">
            <div
              v-if="data.value !== ``"
              style="text-align: center"
              :style="{
                width: data.value.key === 'pay_date' ? '100%' : '100%',
              }"
            >
              <span> {{ data.value }} </span>
            </div>
            <div
              v-else
              style="text-align: center"
              :style="{
                width: data.value.key === 'pay_date' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>

          <template #cell(status_payment)="data">
            <div
              v-if="data.value !== ``"
              style="text-align: center"
              :style="{
                width: data.value.key === 'status_payment' ? '100%' : '100%',
              }"
            >
              <span v-if="data.value == 'ชำระเงินแล้ว'" style="color: green">
                <b> {{ data.value }} </b>
              </span>
              <span v-else-if="data.value == 'รอตรวจสอบ'" style="color: orange">
                <b> {{ data.value }} </b>
              </span>
            </div>

            <div
              v-else
              style="text-align: center"
              :style="{
                width: data.value.key === 'status_payment' ? '100%' : '100%',
              }"
            >
              <span
                v-if="data.item.order_row == 1"
                @click="payment_status(data.item)"
              >
                <b style="font-size: 24px"> + </b>

                <!-- <plus-circle-fill></plus-circle-fill> -->
                <!-- <b-icon icon="exclamation-circle-fill" variant="success"></b-icon> -->
              </span>
              <span v-if="data.item.order_row == 2"> - </span>
            </div>
          </template>

          <template #cell(license)="data">
            <div
              v-if="data.value !== ``"
              style="text-align: center"
              :style="{
                width: data.value.key === 'license' ? '100%' : '100%',
              }"
            >
              <span>
                <b> {{ data.value }} </b></span
              >
            </div>
            <div
              v-else
              style="text-align: center"
              :style="{
                width: data.value.key === 'license' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>

          <template #cell(round)="data">
            <div
              v-if="data.value !== ``"
              style="text-align: center"
              :style="{
                width: data.value.key === 'round' ? '100%' : '100%',
              }"
            >
              <span>
                <b> {{ data.value }} </b></span
              >
            </div>
            <div
              v-else
              style="text-align: center"
              :style="{
                width: data.value.key === 'round' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>

          <template #cell(shop_name)="data">
            <div
              :title="data.item.cust_remark"
              v-b-tooltip.hover
              t
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'shop_name' ? '100%' : '100%',
              }"
              style="text-align: left"
            >
              <span>
                <b> {{ data.value }} </b>
              </span>
            </div>
            <div
              v-else
              style="text-align: center"
              :style="{
                width: data.value.key === 'shop_name' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>

          <template #cell(transfer_date)="data">
            <div
              v-if="data.value !== ``"
              style="text-align: center"
              :style="{
                width: data.value.key === 'transfer_date' ? '100%' : '100%',
              }"
            >
              <span style="color: green">
                <b> {{ data.value }} </b>
              </span>
            </div>
            <div
              v-else
              style="text-align: center"
              :style="{
                width: data.value.key === 'transfer_date' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>
          <template #cell(route)="data">
            <div
              v-if="data.value !== ``"
              style="text-align: center"
              :style="{
                width: data.value.key === 'route' ? '100%' : '100%',
              }"
            >
              <span>
                <b> {{ data.value }} </b>
              </span>
            </div>
            <div
              v-else
              style="text-align: center"
              :style="{
                width: data.value.key === 'route' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>

          <!-- <template #cell(route)="data">
                <div
                    v-if="data.value !== ``"
                    :style="{
                        width: data.value.key === 'shop_name' ? '200px' : '200px',
                    }"
                >
                    <span>
                        <b> {{ data.value }} </b>
                    </span>
                </div>
                <div
                    v-else
                    :style="{
                        width:
                            data.value.key === 'order_date' ? '200px' : '200px',
                    }"
                >
                    -
                </div>
            </template> -->

          <template #cell(shop_code)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'shop_code' ? '100%' : '100%',
              }"
              style="text-align: center"
            >
              <b> {{ data.value }} </b>
            </div>
            <div
              v-else
              style="text-align: center"
              :style="{
                width: data.value.key === 'shop_code' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>

          <template #cell(code)="data">
            <div
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'code' ? '100%' : '100%',
              }"
              style="text-align: center"
            >
              {{ data.value }}
            </div>
            <div
              style="text-align: center"
              v-else
              :style="{
                width: data.value.key === 'code' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>

          <template #cell(cust_code)="data">
            <div
              style="text-align: center"
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'code' ? '100%' : '100%',
              }"
            >
              {{ data.value }}
            </div>
            <div
              style="text-align: center"
              v-else
              :style="{
                width: data.value.key === 'code' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>

          <template #cell(slip)="data">
            <!-- {{ data.item.cust_code }} -->
            <div
              style="text-align: center"
              v-if="data.item.cust_code == ``"
              :style="{
                width: data.value.key === 'code' ? '100%' : '100%',
              }"
            >
              -
              <!-- <b> {{data }}</b> -->
              <!-- <div v-if="data.value.data !== null"> -->
              <!-- <img
                  :src="data.value"
                  height="20px"
                  width="40px"
                  @click="showModal(data.value, data.item)"
                /> -->
              <!-- <img src="@/assets/images/elements/apple-watch.png" /> -->
              <!-- <b-button   variant="success"   @click="ChangImg()">ตกลง</b-button> -->
              <!-- </div> -->
              <!-- <div v-else style="text-align: center">-</div> -->
              <!-- <b> {{ data.value }} </b> -->
            </div>

            <div
              v-else
              style="text-align: center"
              :style="{
                width: data.value.key === 'code' ? '100%' : '100%',
              }"
            >
              <img
                src="@/assets/images/elements/img.png"
                @click="showModal(data.value, data.item)"
                height="25px"
                width="25px"
              />
            </div>
          </template>

          <template #cell(remark)="data">
            <div
              style="text-align: center"
              v-if="data.value !== ``"
              :style="{
                width: data.value.key === 'shop_code' ? '100%' : '100%',
              }"
            >
              <b> {{ data.value }} </b>
            </div>
            <div
              style="text-align: center"
              v-else
              :style="{
                width: data.value.key === 'order_date' ? '100%' : '100%',
              }"
            >
              -
            </div>
          </template>

          <!-- Column: Issued Date -->
          <template #cell(status_order)="data">
            <div
              style="text-align: center"
              :style="{
                width: data.value.key === 'shop_name' ? '100%' : '100%',
              }"
            >
              <span class="text-nowrap">
                <div v-if="data.value == 'ปกติ'" style="color: green">ปกติ</div>
                <div v-else-if="data.value == 'ด่วนมาก'" style="color: red">
                  ด่วนมาก
                </div>
                <div v-else style="color: #b83f00">ด่วน</div>
              </span>
            </div>
          </template>
          <template #cell(actions)="row">
            <div v-if="row.item.checkpage == ``">-</div>
            <div v-else>
              <div v-if="!row.item.index" style="text-align: center">
                <div v-if="row.item.status_payment == `ชำระเงินแล้ว`"></div>
                <b-form-checkbox
                  v-else
                  v-model="row.item.checkbox"
                  name="check-button"
                  switch
                  @input="(value) => check(row.item.checkbox, row.item)"
                >
                </b-form-checkbox>
              </div>
            </div>
          </template>

          <template #cell(cancel)="row">
            <div v-if="row.item.checkpage == ``">-</div>
            <div v-else>
              <div v-if="!row.item.index" style="text-align: center">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-1
                  variant="danger"
                  @click="cancle_invoice(row.item)"
                >
                  ยกเลิก
                </b-button>
              </div>
              <!-- <div>test</div> -->
            </div>
          </template>

          <template #row-details="row">
            <b-card>
              <div class="container">
                <div class="row">
                  <div class="col-2">
                    INVOICE
                    <b-form-input
                      v-model="invoice"
                      placeholder="Enter your invoice"
                      type="number"
                    ></b-form-input>
                  </div>

                  <div class="col-2">
                    <b-button
                      class="ml-6 col-6 mt-2"
                      variant="success"
                      @click="update_invoice(invoice, row.item)"
                    >
                      Update
                    </b-button>
                    <!-- อื่น ๆ
                                <b-form-input
                                    v-model="row.item.remark"
                                    placeholder="-"
                                    disabled
                                ></b-form-input> -->
                  </div>
                  <!-- <div class="col-2"> 
                                
                                <b-button  class="ml-6 col-6 mt-2" variant="success"> Update </b-button>
                          
                            </div>  -->
                </div>
              </div>
              <!-- <ul>
                        <li v-for="(value, key) in row.item" :key="key">
                            {{ key }}: {{ value }} test 
                        </li>
                    </ul> -->
            </b-card>

            <!-- <table border="0" cellpadding="0" cellspacing="0">
           
            <tr height="16" hidden>
                <td colspan="16" v-for="(value, key) in row.item" :key="key">
                
                   {{ key }}
                </td>
             
            </tr>
            <tr height="16" >
                <td colspan="16" v-for="(value, key) in row.item" :key="key">
                    {{ value }} 
                </td>
              
            </tr>
    </table> -->

            <!-- <b-table
          
            :items="fetchInvoices"
            responsive
       
       
        >
        </b-table> -->
          </template>
        </b-table>

        <!-- <template #cell(actions1)="data">
            <div class="text-nowrap">
                <feather-icon
                    :id="`invoice-row-${data.item.id}-send-icon`"
                    icon="SendIcon"
                    class="cursor-pointer"
                    size="16"
                />
            </div> </template> -->
        <!-- </b-card> -->
        <!-- </template> -->
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!-- <span class="text-muted"
                        >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                        {{ dataMeta.of }} entries</span
                    > -->
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="searchEndDate1"
                :total-rows="totalInvoices"
                :per-page="currentPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BFormDatepicker,
  BCollapse,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormFile,
  BModal,
  VBModal,
  BAlert,
  BCardText,
  BCardCode,
    BFormGroup,
  BOverlay,
} from "bootstrap-vue";
import { ref } from '@vue/composition-api';
import flatPickr from "vue-flatpickr-component";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useInvoicesList from "./useInvoiceList";
import axios from "axios";
import invoiceStoreModule from "../invoiceStoreModule";
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'

export default {
  components: {
    BCardCode,
    BFormFile,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BFormDatepicker,
    vSelect,
    flatPickr,
    BCollapse,
    BFormCheckbox,
    BFormCheckboxGroup,
    BModal,
    VBModal,
    BAlert,
    BCardText,
    BOverlay,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    // 'b-tooltip': VBTooltip,
    Ripple,
   
  },
 
  data() {
    return {
      show_data0_total : 0 ,
      show_data1_bill_success : 0,
      show_data2_bill_can : 0,
      fields: {
        // No: "index",
        // รหัสการชำระเงิน: "code",
        // รหัสลูกค้า: "cust_code",
        รอบ : "car_round",
        ทะเบียน: "license",
        ออเดอร์code : "order_code",
        // วันที่จ่ายเงิน: "pay_÷date",
        payment_code: "payment_code",
        transfer_data: "transfer_data",
        ร้านค้า: "shop_name",
        ยอดเงิน: "amount",
        ใบแจ้งหนี้: "invoice",
        วันที่ชำระเงิน: "pay_date",
        สถานะการชำระเงิน: "status_payment",
      },
      options_code : [],
      total_amount : 0,
      // codeSize:"",
      show: false,
      options: [],
      options_checkbox: [],
      options_check: false,
      options_route: [],
      options_carLicense: [],
      checked: false,
      invoice: "",
      chang_img: "",
      form_cust_code: "",
      items_img: "",
      updated: "",
      name_create:"",
      name_update:"",
      check_checkbox: true,
      profileFile:"",
      items: [
        {
          isActive: true,
          age: 40,
          name: { first: "Dickerson", last: "Macdonald" },
        },
        {
          isActive: false,
          age: 21,
          name: { first: "Larsen", last: "Shaw" },
        },
        {
          isActive: false,
          age: 9,
          name: { first: "Mini", last: "Navarro" },
          _rowVariant: "success",
        },
        {
          isActive: false,
          age: 89,
          name: { first: "Geneva", last: "Wilson" },
        },
        {
          isActive: true,
          age: 38,
          name: { first: "Jami", last: "Carney" },
        },
        {
          isActive: false,
          age: 27,
          name: { first: "Essie", last: "Dunlap" },
        },
        {
          isActive: true,
          age: 40,
          name: { first: "Thor", last: "Macdonald" },
        },
        {
          isActive: true,
          age: 87,
          name: { first: "Larsen", last: "Shaw" },
          _cellVariants: { age: "danger", isActive: "warning" },
        },
        {
          isActive: false,
          age: 26,
          name: { first: "Mitzi", last: "Navarro" },
        },
        {
          isActive: false,
          age: 22,
          name: { first: "Genevieve", last: "Wilson" },
        },
        {
          isActive: true,
          age: 38,
          name: { first: "John", last: "Carney" },
        },
        {
          isActive: false,
          age: 29,
          name: { first: "Dick", last: "Dunlap" },
        },
      ],
      formData_create: {
        custCode: "",
        code: "",
        sizeSmall: 0,
        sizeMiddle: 0,
        sizeLarge: 0,
        remark: "",
        total: 0,
        chatId: "",
        role: "R",
        row: [],
        // invoiceCode :[],
        slip: "",
      },
      formData: {
        searchStartDate: "",
        carLicense: "",
        searchRob: "",
      },
      formData_cancle: {
        cancel_remark: "",
      },
      data_img: ``,
      totalRows: 1,
      // currentPage: 1,
      // perPage: 5,
      // pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      update_data: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      showb: false,
      date_update: [],
    };
  },
  //     watch: {
  //         totalInvoices1: {
  //     handler(newName, oldName) {
  //       console.log('obj.a changed');
  //     },
  //     immediate: true,
  //     deep: true
  //   }
  // },
  watch: {
    totalInvoices1: {
      // console.log("")
      handler(val) {
        // console.log(val);
        // console.log("val" , val)
        // console.log("val" , val)
        this.UpdateQ(val, ``);

        // for (let i = 0; i < val.length; i++) {
        //     if (val[i].checkbox == true) {
        //         console.log("test1");
        //         // return
        //     } else {
        //         console.log("test2");
        //     }
        // }

        // for (let i = 0; i < val.length; i++) {

        //     if(val[i].checkbox === true){

        //         // if(val[i].code == this.update_data){

        //         // }

        //         this.update_data.push( val[i])
        //         console.log("val" , val[i])
        //         return 0;

        //     }

        // this.options.push(response.data[i].shopName);
        // }
      },
      deep: true,
    },
    options_check: {
      handler(val) {
        // console.log(val);
      },
    },
    formData_create :{
      handler(val){
        console.log("test" , val)
      }
    },
    name_create: {
      handler(val) {
        console.log(val);
        this.Listinvoice()
      },
    },
    searchStatus: {
      handler(val) {
        console.log(val);
        this.submit_payment(this.searchStatus)
        // this.Listinvoice()
      },
    },
    searchStartDate: {
      handler(val) {
        console.log(val);
        this.submit_payment(this.searchStatus)
        // this.Listinvoice()
      },
    },
    searchEndDate: {
      handler(val) {
        console.log(val);
        this.submit_payment(this.searchStatus)
        // this.Listinvoice()
      },
    },
    statusFilter: {
      handler(val) {
        console.log(val);
        this.submit_payment(this.searchStatus)
        // this.Listinvoice()
      },
    },

  },
  methods: {
    payment_status (e){
      console.log("room",e)
      // this.chang_img = "";
      // this.showb = false;
      this.$refs["submit-new-update"].show();
      this.name_create = e.cust_code
      this.formData_create.code = e.code
      this.Listinvoice()
    },
    payment_status_hide (){
      console.log("room")
      // this.chang_img = "";
      // this.showb = false;
      this.total_amount = 0;
      this.formData_create.slip = "";
      this.name_create = ""
      this.$refs["submit-new-update"].hide();
    },

    check(e, x) {
      if(x.checkbox == true){
      console.log("true")
      this.$refs["my-modal1"].show();
      }else{
      console.log("false")
      }
      // this.$refs["my-modal1"].show();
      this.updated = x;
      console.log("check",this.updated);
      // this.update_invoice();
      // UpdateQ(row.item.checkbox, row.item)
    },

    hide() {
      console.log("1" , this.updated)
      // this.$refs["submit"].hide();
      this.$refs["my-modal1"].hide();
      // this.updated.checkbox =  false
      console.log("2" , this.updated)
      if(this.updated.checkbox == true){
      console.log("true1" , this.updated.checkbox )
      this.updated.checkbox = false
      console.log("true2" , this.updated.checkbox )
      // this.$refs["my-modal1"].show();
      }else{
      console.log("false2")
      }
      // this.$refs["my-modal2"].hide();
    },

    hideModal_submit() {
      // this.$refs["submit"].hide();
      this.total_amount = 0;
      this.formData_create.slip = "";
      this.name_create = ""
      this.$refs["submit-new"].hide();
      // this.$refs["my-modal1"].hide();
      // this.$refs["my-modal2"].hide();
    },

    async cancle_invoice(items) {
      console.log("test", items);
      this.formData_cancle = items;
      this.formData_cancle.cancel_remark = "";
      this.$refs["cancel"].show();
      //   console.log("items", items);
    },

    async createNew(e) {
      this.$refs["submit-new"].show();

    },

    // async submit_cancel_invoice() {
    //   this.show = true;
    //   let payload = {
    //     // invoice: this.formData_cancle.invoice,
    //     // paymentCode: this.formData_cancle.code,
    //   };

    //   try {
    //     const response = await axios.post(
    //       `http://202.129.206.107:11009/listpaymentexport`,
    //       payload
    //     );

    //     if (response.data.status == "Save success") {
    //       this.ClearData();
    //       this.CheckRegister();
    //       this.getCar();
    //       this.getBranch();
    //       this.getRoute();
    //       this.show = false;
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: `ยกเลิกรายการสำเร็จ`,
    //           icon: "UserIcon",
    //           variant: "success",
    //         },
    //       });
    //     }
    //   } catch (errors) {
    //     console.error(errors);
    //   }
    // },

    async submit_cancel_invoice() {
      this.show = true;
      let payload = {
        invoice: this.formData_cancle.invoice,
        paymentCode: this.formData_cancle.code,
      };

      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/cancelpayment`,
          payload
        );

        if (response.data.status == "Save success") {
          this.ClearData();
          this.CheckRegister();
          this.getCar();
          this.getBranch();
          this.getRoute();
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `ยกเลิกรายการสำเร็จ`,
              icon: "UserIcon",
              variant: "success",
            },
          });
        }


      } catch (errors) {
        console.error(errors);
      }


    },


    async submitdownload() {
      // this.show = true;
      let payload = {
        // invoice: this.formData_cancle.invoice,
        // paymentCode: this.formData_cancle.code,
        dateFrom: "",
        dateTo: "",
         status: "",
        //   "round": round.value,
        //  "license" : license.value,
        shopName:  ""
      };

      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/listpaymentexport`,
          payload
        );


        console.log("test", response)
        // if (response.data.status == "Save success") {
        //   this.ClearData();
        //   this.CheckRegister();
        //   this.getCar();
        //   this.getBranch();
        //   this.getRoute();
        //   this.show = false;
        //   this.$toast({
        //     component: ToastificationContent,
        //     props: {
        //       title: `ยกเลิกรายการสำเร็จ`,
        //       icon: "UserIcon",
        //       variant: "success",
        //     },
        //   });
        // }


      } catch (errors) {
        console.error(errors);
      }


    },

    

    async submit_payment(e) {
      // this.show = true;
      // this.searchStartDate  = this.searchStartDate + ''
      // this.searchStartDate  = this.searchStartDate.substring(0, 10)
      
      let payload = {
        status: e,
        dateFrom: this.searchStartDate,
        dateTo: this.searchEndDate?  this.searchEndDate : this.searchStartDate,
        shopName: this.statusFilter
        // dateTo: searchEndDate.value ? searchEndDate.value : searchStartDate.value,
        // shopName: statusFilter.value ? statusFilter.value  : ""
        // status: this.searchStatus
      };

      // console.log(payload , "test")

      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/summarypayment`,
          payload
        );

        console.log("response.data.length " , response.data.status )
        // this.show_data = response.data

        if(response.data.status == "NO DATA"){
          this.show_data0_total = 0;
          this.show_data1_bill_success  = 0;
          this.show_data2_bill_can = 0;

        }else{

      
        if(response.data.length  ==  3){

          for (let i = 0; i < response.data.length; i++) {
            if ( response.data[i].status_payment == 'รอชำระเงิน' ) {
             console.log("1")
             this.show_data0_total = response.data[i].amount
             }
             else if (response.data[i].status_payment  == 'รอตรวจสอบ') {
              console.log("2")
              this.show_data1_bill_success = response.data[i].amount
            }
            else if (response.data[i].status_payment  == 'ชำระเงินแล้ว') {
              console.log("3")
              this.show_data2_bill_can = response.data[i].amount
              }
            }

        }
        else{
          console.log("response.data.length " , response.data.length )

           for (let i = 0; i < response.data.length; i++) {
            if ( response.data[i].status_payment == 'รอชำระเงิน' ) {
             console.log("11")
             this.show_data0_total = response.data[i].amount
             this.show_data1_bill_success = 0,
             this.show_data2_bill_can = 0
             }
             else if (response.data[i].status_payment  == 'รอตรวจสอบ') {
              console.log("22")
              this.show_data1_bill_success = response.data[i].amount
              this.show_data0_total = 0,
              this.show_data2_bill_can = 0
            }
            else if (response.data[i].status_payment  == 'ชำระเงินแล้ว') {
              console.log("33")
              this.show_data2_bill_can = response.data[i].amount
              this.show_data0_total = 0,
              this.show_data1_bill_success = 0
              }
            }
        }

          }

        // let total_array = [];

          // }

        // if (response.data.status == "Save success") {
          // this.ClearData();
          // this.CheckRegister();
          // this.getCar();
          // this.getBranch();
          // this.getRoute();
          // this.show = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: `อัพเดทรายการสำเร็จ`,
          //     icon: "UserIcon",
          //     variant: "success",
          //   },
          // });
        // }


      } catch (errors) {
        console.error(errors);
      }


    },

    async CheckAll() {
      this.show = true;
      if (this.options_check == true) {
        // console.log("test" , this.form_cust_code);
        let checkstatus = false;
        for (let i = 0; i < this.update_data.length; i++) {
          if (this.update_data[i].code == this.form_cust_code) {
            let payload = {
              id: this.update_data[i].invoice_id,
              paymentCode: this.update_data[i].code,
              chat_id: this.update_data[i].chat_id
                ? this.update_data[i].chat_id
                : "",
            };

            if (this.update_data[i].invoice_id !== ``) {
              try {
                const response = await axios.post(
                  `http://202.129.206.107:11009/updatepayment`,
                  payload
                );

                // console.log("response", response.data.status);
                // window.location.reload();
                checkstatus = true;
                // this.$refs["my-modal"].hide();

                // Save success
                if (response.data.status == "Save success") {
                  // console.log("1" , response.data.status)
                  this.ClearData();
                  this.CheckRegister();
                  this.getCar();
                  this.getBranch();
                  this.getRoute();
                  this.show = false;

                  // this.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: `อัพเดทรายการสำเร็จ`,
                  //     icon: "UserIcon",
                  //     variant: "success",
                  //   },
                  // });
                  // window.location.reload();
                }

                // for (let i = 0; i < response.data.length; i++) {
                //     this.options.push(response.data[i].shopName);
                // }
              } catch (errors) {
                console.error(errors);
              }
            }
          }
        }

        if (checkstatus == true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `อัพเดทรายการสำเร็จ`,
              icon: "UserIcon",
              variant: "success",
            },
          });
        }
      }

      //update_data
      // console.log("test001" , totalInvoices1)
    },

    async ChangImg(items) {
      // this.chang_img = items
      //  console.log("items", items  )
    },
    async showModal(e, items) {
      this.chang_img = "";
      this.showb = false;
      this.$refs["my-modal"].show();
      console.log("E", e.code, "items", items);
      let payload = {
        code: items.code,
      };
      // if (this.update_data[i].invoice_id !== ``) {
      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/getimgpayment`,
          payload
        );

        // console.log("response", response.data.status);
        // window.location.reload();
        // checkstatus = true;
        // this.$refs["my-modal"].hide();

        // Save success
        if (response != ``) {
          this.showb = true;

          console.log("tedt", response.data[0].slip);
          let img = response.data[0].slip;
          img = img.data.map((b) => String.fromCharCode(b)).join("");
          // img = new Uint8Array([value[i].slip.data]);
          // const content = new Uint8Array([value[i].slip.data])

          //  console.log("12=",img)
          //  value[i].slip  = img

          this.options_check = false;

          this.chang_img = img;
          this.form_cust_code = items.code;
          this.items_img = items;

          let img_array = [];
          for (let i = 0; i < this.totalInvoices1.length; i++) {
            if (this.totalInvoices1[i].code == this.form_cust_code) {
              img_array.push(this.totalInvoices1[i]);
              // console.log("1")
            } else {
              // console.log("0")
            }
          }

          for (let j = 0; j < img_array.length; j++) {
            if (img_array[j].status_payment == `ชำระเงินแล้ว`) {
              img_array[0].status_payment = `ชำระเงินแล้ว`;
              // console.log("000")
            } else {
              // console.log("0")
            }
          }

          // this.show = false;

          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: `อัพเดทรายการสำเร็จ`,
          //     icon: "UserIcon",
          //     variant: "success",
          //   },
          // });
          // window.location.reload();
        }

        // for (let i = 0; i < response.data.length; i++) {
        //     this.options.push(response.data[i].shopName);
        // }
      } catch (errors) {
        console.error(errors);
      }
      // }
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    async CheckRegister() {
      let payload = {
        flag: "",
      };
      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/listshop`,
          payload
        );

        // console.log("response", response);

        //    response.data;

        for (let i = 0; i < response.data.length; i++) {

          this.options.push(response.data[i].shopName);

          let obj = {
            code: response.data[i].code,
            country: response.data[i].shopName,
          };

          // var total = parseInt( number1 )

          this.options_code.push(obj);
        }

        // return todoItems;
      } catch (errors) {
        console.error(errors);
      }
    },

    async CheckOrder(e) {
      this.loading = true;

      //   console.log("test");
      let data = {
        custCode: "6608041",
        // custCode: e.code,
      };
      // this.formData.custCode = e.code;

      // this.custCode = e.code;
      await listInvoce({
        payload: data,
        cbSuccess: async (res) => {
        //   console.log("res", res.data);
        // data = res.data;
        //   console.log(" this.formData = res.data", data);

          for (let i = 0; i < res.data.length; i++) {
            let obj = {
              text: res.data[i].invoice,
              value: res.data[i].id,
              transferDate: res.data[i].transferDate,
              amount: res.data[i].amount,
              statusPayment: res.data[i].statusPayment,

              check_checkbox: false,
            };
            this.options_checkbox.push(obj);
          }

        //   console.log("this.options_checkbo x", this.options_checkbox);
          // this.options_checkbox = res.data
          // this.formData.lineId = this.userProfile.userId;
          this.loading = false;
        },
        cbError: (e) => {
          console.log("e", e);
        },
      });
    },

    async submitCheck(e, amount, checkbox ,checkbox_text, remark) {

      console.log("Sending11", e, amount, checkbox , checkbox_text);

      let check = {
        id: e,
        invoiceCode :checkbox_text,
        remark :remark
      };


      // if( this.formData_create.row.remark != ``){
      //   console.log("remasrk")
      // }else{
      //   console.log("remark1")
      // }

      for (let i = 0; i < this.formData_create.row.length; i++) {
        // str = str + i;
        console.log("remasrk" , i)
      }







      console.log("check", check);
      console.log("options_checkbox", this.options_checkbox);


      for (let i = 0; i < this.options_checkbox.length; i++) {
        if (this.options_checkbox[i].value == e) {
          console.log("1",i)

          if (this.options_checkbox[i].check_checkbox == false) {
            console.log("2",i)
            this.total_amount = this.total_amount + amount ;

            this.options_checkbox[i].check_checkbox = true;
            this.formData_create.row.push(check);

          } else {
            console.log("3 ",i)
            this.total_amount = this.total_amount - amount ;
            this.options_checkbox[i].check_checkbox = false;

            for (let i = 0; i < this.formData_create.row.length; i++) {
              if (this.formData_create.row[i].id == e) {
                this.formData_create.row.splice(i, 1);
              }
            }
          }
        }
      }

    },


    async Listinvoice() {
      // console.log("e" , e)

      let data = {
        custCode: this.name_create,
        // custCode: e.code,
      };
      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/listinvoice`,
          data
        );

        this.options_checkbox = [];

        for (let i = 0; i < response.data.length; i++) {
            let obj = {
              text: response.data[i].invoice,
              value: response.data[i].id,
              transferDate: response.data[i].transferDate,
              amount: response.data[i].amount,
              statusPayment: response.data[i].statusPayment,
              remark: response.data[i].remark,
              check_checkbox: false,
            };
            this.options_checkbox.push(obj);
          }


      } catch (errors) {
        console.error(errors);
      }
    },
    async Saveinvoice() {
      this.show = true;
      let payload_save = {
        custCode: this.name_create,
        slip: this.formData_create.slip,
        row: this.formData_create.row,
        // invoiceCode :  this.formData.invoiceCode,
        chat_id : ""

      };
      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/savePayment`,
          payload_save
        );

           if (response.data.status == "Save success") {
            this.hideModal_submit();
            // this.ClearData();
            // this.CheckRegister();
            // this.getCar();
            // this.getBranch();
            // this.getRoute();





            this.name_create = ``

            // this.statusFilter = "1"
            // this.statusFilter = ""
            this.ClearData();
          this.CheckRegister();
          this.getCar();
          this.getBranch();
          this.getRoute();
          this.show = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `บันทึกรายการสำเร็จ`,
                icon: "UserIcon",
                variant: "success",
              },
            });
            // window.location.reload();
          }

        // for (let i = 0; i < response.data.length; i++) {
        //     let obj = {
        //       text: response.data[i].invoice,
        //       value: response.data[i].id,
        //       transferDate: response.data[i].transferDate,
        //       amount: response.data[i].amount,
        //       statusPayment: response.data[i].statusPayment,
        //       check_checkbox: false,
        //     };
        //     this.options_checkbox.push(obj);
        //   }


      } catch (errors) {
        console.error(errors);
      }
    },


    async Updateinvoice() {

      let payload_save = {
        code: this.formData_create.code,
        // slip: this.formData_create.slip,
        row: this.formData_create.row,
        // invoiceCode :  this.formData.invoiceCode,
        chat_id : ""

      };

      console.log("payload_save" , payload_save)
      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/savepaymentinvoice`,
          payload_save
        );

           if (response.data.status == "Save success") {
            this.payment_status_hide();

            // this.ClearData();
            // this.CheckRegister();
            // this.getCar();
            // this.getBranch();
            // this.getRoute();





            this.name_create = ``

            // this.statusFilter = "1"
            // this.statusFilter = ""
            this.show = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `บันทึกรายการสำเร็จ`,
                icon: "UserIcon",
                variant: "success",
              },
            });
            // window.location.reload();
          }

        // for (let i = 0; i < response.data.length; i++) {
        //     let obj = {
        //       text: response.data[i].invoice,
        //       value: response.data[i].id,
        //       transferDate: response.data[i].transferDate,
        //       amount: response.data[i].amount,
        //       statusPayment: response.data[i].statusPayment,
        //       check_checkbox: false,
        //     };
        //     this.options_checkbox.push(obj);
        //   }


      } catch (errors) {
        console.error(errors);
      }
    },

    async getCar() {
      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/listCar`
        );

        // console.log("response", response);

        //    response.data;

        for (let i = 0; i < response.data.length; i++) {
          this.options_carLicense.push(response.data[i].carLicense);
        }

        // return todoItems;
      } catch (errors) {
        console.error(errors);
      }
    },
    async getBranch() {
      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/listBranch`
        );

        // console.log("response", response);

        //    response.data;

        for (let i = 0; i < response.data.length; i++) {
          // this.options.push(response.data[i].shopName);
        }

        // return todoItems;
      } catch (errors) {
        console.error(errors);
      }
    },
    async getRoute() {
      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/listRoute`
        );

        // console.log("response", response);

        //    response.data;

        for (let i = 0; i < response.data.length; i++) {
          this.options_route.push(response.data[i].route);
        }

        // return todoItems;
      } catch (errors) {
        console.error(errors);
      }
    },

    async checkUpdate(e, items) {
      console.log("1");
      this.$refs["my-modal1"].show();
    },
    async UpdateQ(e, items) {
      // console.log("e55511111", e, items);

      // this.date_update = e;

      this.update_data = e;
      let number = ``;

      //    invoice_id

      if (items == ``) {
      } else {
        this.show = true;
        let payload = {
          id: items.invoice_id ? items.invoice_id : items.invoice_id,
          paymentCode: items.code,
          chat_id: items.chat_id ? items.chat_id : "",
        };

        console.log("test", payload);

        try {
          const response = await axios.post(
            `http://202.129.206.107:11009/updatepayment`,
            payload
          );

          // console.log("response", response.data.status);
          // window.location.reload();

          if (response.data.status == "Save success") {
            this.ClearData();
            this.CheckRegister();
            this.getCar();
            this.getBranch();
            this.getRoute();
            this.show = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `อัพเดทรายการสำเร็จ`,
                icon: "UserIcon",
                variant: "success",
              },
            });
            // window.location.reload();
          }

          // for (let i = 0; i < response.data.length; i++) {
          //     this.options.push(response.data[i].shopName);
          // }
        } catch (errors) {
          console.error(errors);
        }

        // console.log(
        //     "this.update_datatttttttttttttttttttt",
        //     this.update_data
        // );
        // console.log("this.date_update ", this.date_update);

        //  for (let i = 0; i < response.data.length; i++) {
        //                 this.options.push(response.data[i].shopName);
        // //             }
        //         console.log("test" ,e ,x )

        //         if(e == true ){

        //             this.update_data.push(x)
        //         }else{

        //             //เช็ค x.code ว่ามีในนี้ไหม  update_data

        //             for (let i = 0; i < this.update_data.length; i++) {
        //                 console.log("x.code ", x.code  , this.update_data[i].code)

        //                 if(x.code == this.update_data[i].code){
        //                     console.log("1")
        //                     // this.update_data.splice(this.update_data.indexOf( x.code  ), 1);
        //                 }else{
        //                     console.log("2")
        //                 }

        //                 // this.options.push(response.data[i].shopName);
        //             }

        //             //ถ้ามี ลบ update_data.code
        //         console.log("555",this.update_data)

        //         }

        //         console.log("update_data", this.update_data)

        // try {
        //     const response = await axios.post(
        //         `http://202.129.206.107:11009/listshop`
        //     );

        //     console.log("response", response);

        //     for (let i = 0; i < response.data.length; i++) {
        //         this.options.push(response.data[i].shopName);
        //     }

        // } catch (errors) {
        //     console.error(errors);
        // }
      }
    },
    async Update() {
      this.show = true;
      // console.log("e555" , e)

      // this.update_data = e

      //  for (let i = 0; i < response.data.length; i++) {
      //                 this.options.push(response.data[i].shopName);
      //             }
      // console.log("test" , totalInvoices1)

      //     console.log("this.update_data",   this.update_data)

      let b = [];

      // this.date_update

      console.log("this.update_data", this.date_update);

      for (var i = 0; i < this.update_data.length; i++) {
        if (this.update_data[i].checkbox == true) {
          // console.log("1" ,  this.date_update[i]);

          // this.update_data[i].size_small = `10`
          // this.update_data[i].size_middle = `10`
          // this.update_data[i].size_large = `10`
          // this.update_data[i].max_large = this.date_update[i].max_large

          b.push(this.update_data[i]);
        } else {
          console.log("2");
        }
        //   if ( b.indexOf( this.update_data[i].checkbox ) == true ) b.push( this.update_data[i]  );
      }

      //      console.log("this.update_data[i] " , this.update_data[i] )

      // let test = {
      //     id: 18,
      //     code: "P00005",
      //     orderDate: "2023-03-23 00:35:35",
      //     dueDate: null,
      //     transferDate: "2023-03-23 00:35:35",
      //     custCode: "6603002",
      //     sizeSmall: "12455",
      //     sizeMiddle: 1881,
      //     sizeLarge: 1112,
      //     remark: "ttyy",
      //     total: 4238,
      //     statusOrder: "ปกติ",
      //     statusMain: "รอจัดรถ",
      //     updateDate: "2023-03-29 14:19:48",
      //     lineId: "Uafc47e26b9bb051412f9ecbf0c06346b",
      //     chatId: "U65410582defe7d3b679c62670ad6de44",
      // };
      console.log(" b", b);
      // console.log(" test", b);

      // if(e == true ){

      //     this.update_data.push(x)
      // }

      let payload = {
        transferDate: this.formData.searchStartDate,
        license: this.formData.carLicense,
        round: this.formData.searchRob,
        staffId: 1,
        row: b,
      };

      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/savetransfer`,
          payload
        );

        console.log("response", response.data.status);
        // window.location.reload();

        if (response.data.status == "Save success") {
          this.ClearData();
          this.CheckRegister();
          this.getCar();
          this.getBranch();
          this.getRoute();
          this.show = false;
          // window.location.reload();
        }

        // for (let i = 0; i < response.data.length; i++) {
        //     this.options.push(response.data[i].shopName);
        // }
      } catch (errors) {
        console.error(errors);
      }
    },

    async update_invoice(e, items) {
      this.show = true;
      console.log("e", e, "items", items);

      let payload = {
        chat_id: items.chat_id ? items.chat_id : "",
        invoice: this.invoice,
        id: items.id,
        remark: items.remark,
        size_large: items.size_large,
        size_middle: items.size_middle,
        size_small: items.size_small,
      };

      try {
        const response = await axios.post(
          `http://202.129.206.107:11009/saveinvoice`,
          payload
        );

        console.log("response", response.data.status);
        // window.location.reload();

        if (response.data.status == "Save success") {
          this.ClearData();
          this.CheckRegister();
          this.getCar();
          this.getBranch();
          this.getRoute();
          this.show = false;
        }
      } catch (errors) {
        console.error(errors);
      }
    },

    ClearData() {
      console.log("1", this.searchStartDate);
      this.searchStartDate = "";
      this.searchEndDate = "";
      this.searchRout = "";
      this.searchStatus = "";
      this.searchRoute = "";
      this.statusFilter = "";
      this.license = "";
      this.round = "";
      this.formData_create = {
        custCode: "",
        code: "",
        sizeSmall: 0,
        sizeMiddle: 0,
        sizeLarge: 0,
        remark: "",
        total: 0,
        chatId: "",
        role: "R",
        row: [],
        slip: "",
      };
      this.options_code = [];
      this.total_amount = 0;
    },
    ClearDataUpdate() {
      this.formData = {
        searchStartDate: "",
        carLicense: "",
        searchRob: ""
      };
      this.options_code = [];
        this.total_amount = 0;
      // console.log("1", this.searchStartDate);
      // this.searchStartDate = "";
      // this.searchEndDate = "";
      // this.searchRout = "";
      // this.searchStatus = "";
      // this.searchRoute = "";
      // this.statusFilter = "";
    },
    total() {
      console.log("test");
    },
  },
  mounted() {
    // this.submitdownload();
    // this.$refs["my-modal1"].show();
    this.CheckRegister();
    this.getCar();
    this.getBranch();
    this.getRoute();
    this.submit_payment("");
    //console.log("exportD" , exportD)
    // this.submit_payment_download();

    // console.log("1");
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = "app-invoice";
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    // const statusOptions = [
    //   'Downloaded',
    //   'Draft',
    //   'Paid',
    //   'Partial Payment',
    //   'Past Due',
    // ]

    const statusOptions = ["รอชำระเงิน", "รอตรวจสอบ", "ชำระเงินแล้ว"];
    const Optionsrob = ["1", "2", "3"];

    // const statusOptionsshop = [
    // ]

    const {
      fetchInvoices,
      fetch_shop,
      tableColumns,
      perPage,
      excel,
      currentPage,
      totalInvoices,
      totalInvoices1,
      totalInvoices2,
      totalInvoices3,
      totalInvoices4,
      totalInvoices5,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchStartDate,
      searchEndDate,
      searchEndDate1,
      license,
      round,
      searchStatus,
      searchRob,
      searchRout,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      params,
      statusFilter,

      refetchData,
      exportD,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList();

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
      fetchInvoices,
      fetch_shop,
      tableColumns,
      perPage,
      excel,
      currentPage,
      totalInvoices,
      totalInvoices1,
      totalInvoices2,
      totalInvoices3,
      totalInvoices4,
      totalInvoices5,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      searchStartDate,
      searchEndDate,
      searchEndDate1,
      license,
      round,
      searchStatus,
      searchRob,
      searchRout,
      statusFilter,
      //   statusOptionsshop,
      Optionsrob,
      refetchData,
      params,
      statusOptions,
      exportD,
      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    };
  },
};
</script>

<style lang="scss" scoped>
.hidden_header {
  display: none;
}
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

// .invoice-filter-select[data-v-5cb60a6f] {
//     min-width: 213px;
// }
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

// table, td, th {
//   border: 1px solid black;
// }

table {
  //   border-collapse: collapse;
  width: 120%;
}

th {
  height: 70px;
}

[dir] .custom-control-input[disabled] ~ .custom-control-label::before,
[dir] .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #a29a9a;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #f3f2f7;
  text-align: center;
}

[dir] .table th,
[dir] .table td {
  padding: 0.72rem 0.3rem;
}
</style>
